@import url("https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Share Tech Mono", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(
      to bottom,
      rgb(0, 0, 0),
      rgba(0, 0, 0, 0.8),
      rgb(0, 0, 0)
    ),
    url("img/background01.jpg");
  background-size: cover;
  color: #2dd5ff;
  text-align: center;

  transform: translate(0, 0, 0);
  backface-visibility: hidden;
  transform-style: preserve-3d;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
