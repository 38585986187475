* {
  margin: 0;
  padding: 0;
}

html,
body {
  /* height: calc(var(--vh, 1vh) * 100); */
  height: 100%;
  width: 100vw;
  max-width: 100vw;
  background-color: black;
  overflow: hidden;
}

.App {
  grid:
    "header" min-content
    "main" auto
    "footer  " min-content;
  height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0.5rem;
  display: grid;
  max-width: 100vw;
  grid:
    "header" min-content
    "main" auto
    "footer" min-content;
}

header {
  grid-area: header;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.large-menu {
  display: none;
}
.small-menu {
  width: 3rem;
  margin: 0.5rem;
}

main {
  grid-area: main;
  justify-self: center;
  max-width: 100vw;
  padding: 0.5rem;
}

a {
  text-decoration: none;
  color: aqua;
}

p {
  text-align: justify;
  text-indent: 2rem;
  margin: 1rem 0;
}

.home {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.jumbo-font {
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 1.8rem;
  line-height: 1.8rem;
  animation: slide-right 1.2s forwards;
}

.link {
  font-weight: bold;
  color: #2dd5ff;
}

.link:hover {
  color: white;
}

.project-item {
  display: flex;
  flex-direction: row;
  margin: 0.5rem;
  border-bottom: solid 1px #2dd5ff;
}

.project-image {
  width: 10rem;
  border: 1px solid #2dd5ff;
  margin: 0.5rem;
  padding: 1rem;
}
.project-text {
  width: 100%;
  text-align: left;
  margin: 0.5rem;
}

.archviz {
  display: flex;
  flex-direction: column;
  text-align: left;
  text-indent: 2rem;
}

.skill-card {
  opacity: 0;
  animation: fade-in 0.6s forwards;
}
.general-title {
  margin: 0.5rem auto;
}

.photo-frame {
  margin-top: 1rem;
  width: 100%;
  opacity: 0;
  animation: slide-up 1s forwards;
  -webkit-animation: slide-up 1s forwards;
}

.contacts {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-card {
  display: flex;
  width: 90vw;
  max-width: 30rem;
  margin: 0.5rem;
  animation: fade-in 1s forwards;
  -webkit-animation: fade-in 1s forwards;
}
.title {
  width: 100vw;
}

footer {
  grid-area: footer;
  padding: 2rem;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  * {
    margin: 0 0.5rem;
  }
}

@media screen and (min-width: 300px) {
  .App {
    grid:
      "header header" min-content
      "main main" auto
      "footer footer " min-content;
    height: calc(var(--vh, 1vh) * 100);
  }
  .main {
    max-width: 30rem;
  }

  .project-item {
    display: flex;
    flex-direction: column;
  }

  p {
    width: 98%;
  }
  .archviz {
    overflow-x: hidden;
  }
  .jumbo-font {
    font-size: 2rem;
    line-height: 2.8rem;
  }
  .footer {
    flex-direction: column;
  }
}

@media screen and (min-width: 500px) {
  .App {
    grid:
      "header header header" min-content
      "main main main" auto
      "footer footer footer" min-content / 1fr 1fr 1fr;
    height: calc(var(--vh, 1vh) * 100);
  }
  main {
    max-width: 40rem;
  }
  .project-item {
    display: flex;
    flex-direction: column;
  }
  .small-menu {
    width: 4.5rem;
    margin: 1rem;
  }
  .jumbo-font {
    font-size: 2.2rem;
    line-height: 3.5rem;
  }
  .footer {
    flex-direction: row;
  }
}
@media screen and (min-width: 700px) {
  .App {
    grid:
      ". . header header header header . ." min-content
      ". . main main main main . ." auto
      ". . footer footer footer footer . ." min-content;
    height: calc(var(--vh, 1vh) * 100);
  }
  main {
    max-width: 50rem;
    padding: 0.5rem;
  }
  .project-item {
    display: flex;
    flex-direction: row;
  }
  .large-menu {
    display: block;
  }
  .small-menu {
    display: none;
  }
  .general-title {
    margin: 1rem auto;
  }
  .home {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    text-align: left;
  }
  .jumbo-font {
    font-size: 2.2rem;
    line-height: 3rem;
  }
  .introText {
    text-align: left;
    text-indent: 2rem;
    line-height: 1.7rem;
  }
  .title {
    margin: 1rem;
  }
  .photo-frame {
    width: 45%;
  }
}

.portfolioImage {
  min-width: 15rem;
  width: 15rem;
}

.video-responsive {
  overflow: hidden;
  position: relative;
  min-width: 15rem;
  min-height: 12rem;
  border-radius: 1rem;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
}

@media screen and (min-width: 1200px) {
  main {
    width: 60rem;
  }
  .jumbo-font {
    font-size: 2.6rem;
    line-height: 4rem;
    padding: 0.5rem 0;
  }
  .foto-frame {
    margin-left: 2rem;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slide-right {
  from {
    opacity: 0;
    transform: translateX(5rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(5rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(5rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide_up {
  from {
    opacity: 0;
    transform: translateY(5rem);
  }
  to {
    opacity: 0.5;
    transform: translateX(0);
  }
}

.stack {
  display: grid;
  grid-template-columns: 1fr;
  border-bottom: 0.1rem #2dd5ff solid;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.stack span {
  font-weight: bold;
  grid-row-start: 1;
  grid-column-start: 1;
  font-size: 3rem;
  --stack-height: calc(100% / var(--stacks) - 1px);
  --inverse-index: calc(calc(var(--stacks) - 1) - var(--index));
  --clip-top: calc(var(--stack-height) * var(--index));
  --clip-bottom: calc(var(--stack-height) * var(--inverse-index));
  clip-path: inset(var(--clip-top) 0 var(--clip-bottom) 0);
  animation: stack 340ms cubic-bezier(0.46, 0.29, 0, 1.24) 1 backwards
      calc(var(--index) * 120ms),
    glitch 2s ease infinite 2s alternate-reverse;
}

.stack span:nth-child(odd) {
  --glitch-translate: 8px;
}

.stack span:nth-child(even) {
  --glitch-translate: -8px;
}

@keyframes stack {
  0% {
    opacity: 0;
    transform: translateX(-50%);
    text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
  }
  60% {
    opacity: 0.5;
    transform: translateX(50%);
  }
  80% {
    transform: none;
    opacity: 1;
    text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
  }
  100% {
    text-shadow: none;
  }
}

@keyframes glitch {
  0% {
    text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
    transform: translate(var(--glitch-translate));
  }
  2% {
    text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
  }
  4%,
  100% {
    text-shadow: none;
    transform: none;
  }
}
